<template>
  <v-container>
    <v-row class="justify-center align-self-start">
      <v-col class="col-md-8 col-sm-10">
        <v-card class="py-9 px-12">
          <v-card-title
            class="primary--text headline"
          >
            Release Notes
          </v-card-title>

          <v-card-subtitle class="subtitle-1">
            What's New in iAdOps?
          </v-card-subtitle>

          <v-card-text class="font-weight-medium">
            <div
              id="2023-10"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                October 2023
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span>Alias Mode (v4) (Generally Available)
                </h4>

                <p>
                  Alias Mode is now open to all internal users. Internal users can enable Alias Mode for
                  their account by navigating to the Settings option that appears on click of their profile
                  in the sidebar. Once in the Settings view, internal users are provided with a dropdown to
                  choose which external user they would like to alias. They can then toggle Alias Mode on,
                  save their settings, and the application will refresh with the aliased user’s settings.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>
                  <span class="primary white--text px-1 mr-1">Update</span> iAdOps 5 Beta
                </h4>

                <p>
                  We continue to iterate on iAdOps 5, our next-generation interfaces and APIs. Over the last
                  few months, we've exercised its features and functionality, obtaining valuable feedback
                  from our internal users. Now, we're maturing it from a proof of concept to a
                  production-ready application. More to come as we prepare for another round of
                  general user acceptance testing.
                </p>

                <p>
                  To learn more about iAdOps 5, visit <a
                    href="https://beta.iadops.com/docs"
                    target="_self"
                    class="font-weight-bold"
                  >our Beta documentation</a>.
                </p>
              </div>
            </div>
            <div
              id="2023-08"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                August 2023
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> General Maintenance (v4)
                </h4>

                <p>
                  Security and performance audits, alongside refactors and rewrites, were executed
                  to assure the continued stability of our system as we scale.
                </p>
              </div>
            </div>

            <div
              id="2023-05"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                May 2023
              </h3>

              <div>
                <h4>
                  <span class="coolgray white--text px-1 mr-1">Fix</span> Normalize v4 and v5 Settings (v4)
                </h4>

                <p>
                  To facilitate the transition from v4 to v5, we've updated v4 settings to coexist
                  with v5 settings. This will prevent testers losing settings or encountering
                  unexpected results as they switch between versions.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Alias Mode Settings (v4) (Limited Audience)
                </h4>

                <p>
                  Alias Mode facilitates internal users’ testing, troubleshooting, and user experience
                  analysis by enabling them to view the application interface and functionality as an
                  external user, also known as the aliased user.
                </p>

                <p>
                  Previously, internal users opened a ticket with the Technology Department requesting
                  Alias Mode be enabled for their account. Now, internal users can enable Alias Mode for
                  their account by navigating to the Settings option that appears on click of their profile
                  in the sidebar. Once in the Settings view, internal users are provided with a dropdown to
                  choose which external user they would like to alias. They can then toggle Alias Mode on,
                  save their settings, and the application will refresh with the aliased user’s settings.
                </p>

                <p>
                  This update also resolves an issue where Alias Mode could deactivate if the internal user
                  updated their user record through actions like marking a message read.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> Viewable Impressions Column for Pacing (Beta)
                </h4>

                <p>
                  The new Viewable Impressions column displays the number of viewable impressions, as reported by
                  Google Ad Manager, for each row in the table.
                </p>

                <p>
                  While this data is part of the calculation made in v4 to determine the Delivered value
                  in the Pacing table, exposing this column apart from Delivered allows users to analyze
                  the data in a new way.
                </p>

                <p>
                  Currently in <a
                    href="https://beta.iadops.com"
                    target="_blank"
                  ><strong>Beta</strong></a>.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> iAdOps 5 Beta
                </h4>

                <p>
                  iAdOps 5 marks a major release for our application. Over the last year,
                  we've rebuilt core components from the ground up, using the latest technologies
                  and best practices to deliver a faster, more reliable, and user-friendly experience
                  for our users.
                </p>

                <p>
                  We've also introduced user customizations to power our new Bookmark and Network Settings.
                  Check out Dark Mode, Full-Screen Mode, and more as you navigate throughout the application.
                </p>

                <p>
                  To learn more about iAdOps 5, visit <a
                    href="https://beta.iadops.com/docs"
                    target="_self"
                    class="font-weight-bold"
                  >our Beta documentation</a>.
                </p>
              </div>
            </div>

            <div
              id="2022-06"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                June 2022
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Access Table Menus via Double-Click
                </h4>

                <p>
                  Users who prefer double-clicking over right-clicking can now access table menus
                  via their preferred method. Right-clicking and double-clicking produce the same outcome,
                  a context menu for the row or table header selected.
                </p>
              </div>

              <div>
                <h4>
                  <span class="coolgray white--text px-1 mr-1">Fix</span> 10-Day History Requests
                </h4>

                <p>
                  The 10-Day History view, available on right-click of Pacing rows, should render
                  the expected table after sending a well-formed request to the iAdOps API
                  and receiving the expected response.
                </p>
              </div>

              <div>
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> Server
                </h4>

                <p>
                  We've integrated our application with a thin server that supports secure,
                  cross-server communications between privileged users, recognized systems, and itself.
                </p>
              </div>
            </div>

            <div
              id="2022-05"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                May 2022
              </h3>
              <div>
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> Group-Level Metrics
                </h4>

                <p>
                  Calculates the metrics for each group of rows, then renders them to the
                  appropriate group header.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> AdX/OB Report Iterations
                </h4>

                <p>
                  User feedback continues leading the development of our programmatic reporting.
                  Updates to its merge, group, and filter options have been implemented to ease
                  new users into the data and interface. Most notably, default settings group
                  the data by Demand Source, and group-level metrics have been added to group
                  headers. We also introduced week-level logic to empower week-over-week analysis.
                </p>

                <p>
                  Available to a limited audience.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request access.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Embed Custom Reports via Tableau Connected Apps
                </h4>

                <p>
                  Tableau Connected Apps are the latest embed option allowing iAdOps to seamlessly integrate
                  Custom Reports with our web components. Users no longer need to sign in to Tableau after
                  verifying their account with iAdOps, as iAdOps will handle the auth workflow behind the scenes.
                </p>
              </div>
            </div>

            <div
              id="2022-04"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                April 2022
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> AdX/OB Report Iterations
                </h4>

                <p>
                  Programmatic reports for AdX/OB, AdX Health, and Network Health have been released
                  to a limited subset of external users. Access logic has been updated accordingly.
                </p>

                <p>
                  Available to a limited audience.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request access to iAdOps' programmatic reports.
                </p>
              </div>

              <div>
                <h4>
                  <span class="coolgray white--text px-1 mr-1">Fix</span> Preview &amp; Tearsheet
                </h4>

                <p>
                  The Preview & Tearsheet view, available on right-click of Pacing rows, should render
                  the expected list of creatives after sending a well-formed request to the iAdOps API
                  and receiving the expected response. The previous app version was sending a request
                  that did not meet the API specifications, leading to a 404 response from the iAdOps API.
                </p>

                <p>
                  If you encounter a Preview &amp; Tearsheet issue after April 13, you may wish to clear your cache or
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    open a ticket
                  </a> to report the possible regression.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Cache Controls
                </h4>

                <p>
                  Users' browsers have been loading files across versions, leading to unexpected,
                  inconsistent application behavior because files from different version are interacting.
                  This update prompts users' browsers to reference the network before relying on the cache.
                </p>

                <p>
                  Users may experience a performance hit on their initial load of this version's
                  various files while browsers update their cache. The New Content prompt will
                  indicate this to the user so they're aware of the change impacting their cache.
                </p>
              </div>
            </div>

            <div
              id="2022-03"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                March 2022
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Redirect v1 Reports Route to Dashboard
                </h4>

                <p>
                  Some users ported from v1 may be accustomed to the v1 Reports route,
                  so we've configured a redirect from /reports to the Dashboard on the home page.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Expose IDs via Column Controls
                </h4>

                <p>
                  Users can expose ID/Code columns via the Column Controls view, available to users
                  on right-click of any column header.  This adjusts for recent updates to our
                  Column Controls and Export logic, which align the export and view more closely,
                  no longer assuming ID/Code columns are desirable in the export if they're hidden.
                  in the web interface.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> User Analytics
                </h4>

                <p>
                  Modernizes and extends our user analytics setup to address recent
                  dependency updates, like the move to modular loading and changes to event logging.
                </p>
              </div>

              <div>
                <h4>
                  <span class="coolgray white--text px-1 mr-1">Fix</span> Render of 10-Day History's CTR & Viewable Impressions
                </h4>

                <p>
                  The 10-Day History reports' CTR and Viewable Impressions columns should appear as
                  percentages and numbers, respectively.
                </p>
              </div>

              <div>
                <h4>
                  <span class="coolgray white--text px-1 mr-1">Fix</span> Exports' Sorting & Formatting
                </h4>

                <p>
                  Exports should reflect the view's sorting, while numerical and percentage values
                  should export accurately and consistently.
                  If you encounter an export issue after March 1st, you may wish to clear your cache or
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    open a ticket
                  </a> to report the possible regression.
                </p>
              </div>
            </div>

            <div
              id="2022-02"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                February 2022
              </h3>

              <div>
                <h4>
                  <span class="coolgray white--text px-1 mr-1">Fix</span> Documentation Permissions
                </h4>

                <p>
                  Permissions have been updated to assure all users can access the PDF
                  documentation linked in the User Docs page, and all users can snooze
                  or permanently dismiss feature prompts. If you encounter a related
                  issue after February 28th, you may wish to clear your cache or
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    open a ticket
                  </a> to report the possible regression.
                </p>
              </div>

              <div>
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> Select All &amp; Toggle All Options
                </h4>

                <p>
                  With the new "Select All" and "Toggle All" options, users can quickly include or
                  exclude all options listed for an input, empowering them to enter a state where they
                  can deselect undesired options. This is particularly useful when users are faced with
                  numerous options and the need to select all or most of those options.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Date Sorting
                </h4>

                <p>
                  Date sorting silently failed to convert dates to a Unix timestamp our sorting logic could process.
                  This is now fixed in the current version.
                </p>

                <p>
                  If you encounter this issue after February 28, 2022, you may wish to clear your cache or
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    open a ticket
                  </a>.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Extended Event Monitoring
                </h4>

                <p>
                  With greater observability into our application, our engineers have more information
                  supporting their development efforts, leading to fewer bugs reaching production
                  and faster issue resolution when they do.
                </p>
              </div>
            </div>

            <div
              id="2022-01"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                January to February 2022
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> New Data Source for Discrepancy Reports
                </h4>

                <p>
                  The Discrepancy report has been updated on the backend with new data sources.
                  Some numbers may be slightly different due to the resulting changes to our data strategy.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a> should you encounter any missing or markedly inconsistent data.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> General Maintenance
                </h4>

                <p>
                  Security and performance audits, alongside refactors and rewrites, were executed
                  to assure the continued stability of our system as we scale.
                </p>
              </div>
            </div>

            <div
              id="2021-12"
            >
              <h3
                class="mb-3 subtitle-1 nearblack--text font-weight-bold"
              >
                December 2021
              </h3>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Table Pagination: New Page Count
                </h4>

                <p>
                  The table pagination tool is located in the page header of every table view.
                  Updates include the current page number, the total page count, and a more
                  minimal design of the prev/next controls, represented by arrow icons.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Rows Per Page: New Row Range and Row Count
                </h4>

                <p>
                  Alongside the table pagination tool, the Rows Per Page tool, also located in the
                  header of every table view, has been updated.
                </p>
                <p>
                  The Rows Per Page tool's label now includes the index of the rows on the
                  page, as well as the total row count, e.g. "Rows 1 - 100 of 1,000".
                </p>

                <p>
                  We've also updated the options' text for clarity, adding "/ Page" to
                  relevant options, e.g. "100 / Page".
                </p>
              </div>

              <div>
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> Context Menus Replace Table Icons
                </h4>

                <p>
                  Right-click any column header or row to access the new context menu.
                  The right-click action replaces the click of iconography once bound to the left
                  of every column header and row. The new interaction via context menus allows users
                  to access actions wherever they may be in the table. At once, it boosts performance
                  at the time of table renders by simplifying the HTML inside every table cell.
                  That means users can engage with their data faster than previous versions allowed.
                </p>
              </div>

              <div>
                <h4>
                  <span class="purple white--text px-1 mr-1">Update</span> Column Resizer: Faster and Easier to Use
                </h4>

                <p>
                  Users can drag the new column header icon to resize columns quickly and easily.
                </p>

                <p>
                  The first iteration of the column resizer suffered experience issues due to numerous
                  style recalculations impacting performance, among other things. While we revisit
                  that implementation, we've surfaced an icon in each column header that performs
                  that same functionality without the lag.
                </p>
              </div>

              <div>
                <h4>
                  <span class="primary white--text px-1 mr-1">New</span> Organization, Person, & Project Pages Get Saved Filters
                </h4>

                <p>
                  All internal users can now leverage the Saved Filters at the top of our Organization,
                  Person, and Project pages.
                </p>

                <p>
                  Don't see your favorite filters among the current options?
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request its addition.
                </p>
              </div>

              <div>
                <h4>
                  <span class="midgray white--text px-1 mr-1">Fix</span> Custom Reports Navigation
                </h4>

                <p>
                  Stabilized render of Custom Reports for Users navigating between the Custom Reports
                  Dashboard and a Custom Report.
                </p>

                <p>
                  Users navigating between the Custom Reports Dashboard and a Custom Report may have
                  seen a blank page, as opposed to users who deep linked into the Custom Report,
                  who always saw the Sign In page or report, as intended.
                </p>
              </div>
            </div>

            <div
              id="2021-11"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                November 2021
              </h3>

              <div>
                <h4>
                  Resize Columns
                </h4>

                <p>
                  Hover over the right edge of every table cell to expose the Column Resizer.
                  Click and drag the element horizontally to set the selected column's width.
                  Click again to release the column.
                </p>
              </div>

              <div>
                <h4>
                  Order Columns
                </h4>

                <p>
                  Re-arrange columns for faster analysis using the Column Picker
                  located in every table's header. Exports uphold column order.
                </p>
              </div>

              <div>
                <h4>
                  Freeze Columns
                </h4>

                <p>
                  Pin columns to freeze them in place using the Column Picker
                  located in every table's header.
                </p>
              </div>

              <div>
                <h4>Numerous Stability Enhancements</h4>

                <p>
                  Among recent updates, we've iterated on the accessibility
                  of our search input to improve the experience for users
                  navigating via keyboard, voice over, and voice control.
                </p>
              </div>
            </div>

            <div
              id="2021-10"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                October 2021
              </h3>

              <div>
                <h4>
                  Group & Merge By More Dimensions
                </h4>

                <p>
                  The Group and Merge dropdowns include numerous more dimensions
                  to breakdown or summarize as needed. Open the Tools menu in
                  every report's header to access this feature.
                </p>

                <p>
                  Don't see your favorite dimension among the latest options?
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request its addition.
                </p>
              </div>

              <div>
                <h4>
                  Enhanced Organization- and User-Level Permissions & Settings
                </h4>

                <p>
                  Not only has security improved in this release,
                  the options for back-end customizations have too.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request customizations to your table views &mdash;
                  from sorting and column visibility to grouping,
                  merging, pre-filtering, and more.
                </p>
              </div>

              <div>
                <h4>
                  AdX and OB Updates
                </h4>

                <p>
                  We continue to iterate on the AdX and OB reports with our
                  Early Adopters' feedback in mind.
                  Hover over column headers to learn more where needed, or use
                  new Merge options to summarize across a greater range of fields.
                </p>

                <p>
                  Available to a limited internal audience.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request access.
                </p>
              </div>
            </div>

            <div
              id="2021-09"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                September 2021
              </h3>

              <div>
                <h4>
                  Custom Reports Admin Dashboard
                </h4>

                <p>
                  Admins can review which reports we've embedded alongside
                  which users have been granted access to each report.
                </p>
              </div>

              <div>
                <h4>Numerous Stability Enhancements</h4>

                <p>
                  Among recent updates, we've iterated on the v3 release with
                  maintenance and scalability in mind. Security and dependency
                  management were also addressed.
                </p>
              </div>
            </div>

            <div
              id="2021-08"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                August 2021
              </h3>

              <div>
                <h4>
                  Distinguishes Between Creatives With & Without Preview URLs
                </h4>

                <p>
                  In the Tearsheet feature, we've signaled to users when a creative
                  cannot produce a preview URL.
                </p>
              </div>

              <div>
                <h4>
                  Sets Default Date Range on Overview: Last Month to Date
                </h4>

                <p>
                  To manage the Overview page's performance issues, impacted
                  over time by the growing amount of data loaded to the table,
                  we reduced the set of initially rendered items to those from
                  the beginning of last month to now. Users can use the filter
                  to query the requested data beyond this initial date range.
                </p>
              </div>
            </div>

            <div
              id="2021-07"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                July 2021
              </h3>

              <div>
                <h4>
                  Saved Filters (Limited)
                </h4>

                <p>
                  Saved Filter views are available on request and visible
                  to users with access. This is the first step toward
                  releasing the feature to a wider audience.
                </p>
              </div>

              <div>
                <h4>
                  User Aliasing
                </h4>

                <p>
                  Internals can now adopt the identity of an iAdOps external user,
                  giving them the ability to view iAdOps just as their user does.
                </p>
              </div>

              <div>
                <h4>
                  Multi-Sort
                </h4>

                <p>
                  To access this feature, click on the table header you wish to sort by.
                  Continue clicking to toggle through the options. Options are ASC, DSC, and off.
                </p>
              </div>

              <div>
                <h4>
                  Continued Performance Improvements
                </h4>

                <p>
                  Manipulate larger data sets. Our latest performance enhancements
                  have optimized our interactions for network speeds and device
                  memory.
                </p>
              </div>

              <div v-if="isDevUser">
                <h4 class="toasted--text text-uppercase">
                  Known Issues
                </h4>

                <ul class="mb-6">
                  <li>
                    Column Widths, Headers, & Tooltips
                  </li>
                  <li>
                    Refine Time Form Rules on Duration
                  </li>
                  <li>
                    Export Sorting Reflects Web View's Sorting
                  </li>
                  <li>
                    Flash of "Choose a Publisher" View at Page Load
                  </li>
                  <li>
                    Demo Mode Updates
                  </li>
                  <li>
                    Toggle Groups' Performance is Slow if Numerous Groups
                  </li>
                  <li>
                    Performance of Date Filter at Network Health
                  </li>
                  <li>
                    Performance of Export as XLSX
                  </li>
                  <li>
                    Performance of Table Render
                  </li>
                  <li>
                    Cross-Link Between Prod and Beta
                  </li>
                </ul>
              </div>
            </div>

            <div
              id="2021-06"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                June 2021
              </h3>

              <div>
                <h4>
                  New Dashboard Card: Favorite Reports
                </h4>

                <p>
                  The Dashboard received an update to accommodate its growth,
                  which begins with the new Reports card. For externals,
                  this lists all their available reports by network. For internals,
                  this lists the reports they've indicated are their favorites.
                </p>
              </div>

              <div>
                <h4>
                  Performance
                </h4>

                <p>
                  Manipulate larger data sets. Our latest performance enhancements
                  have optimized our interactions for network speeds and device
                  memory.
                </p>
              </div>

              <div>
                <h4>Informative Progress Loaders and Error Messages</h4>

                <p>
                  Developers and users alike will receive more informative
                  messages from the application at key interaction points,
                  including error states.
                </p>
              </div>
            </div>

            <div
              id="2021-05"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                May 2021
              </h3>

              <div>
                <h4>
                  AdX Health Reports
                </h4>

                <p>
                  Monitor AdX performance and coverage.
                </p>

                <p>
                  The AdX Health Report is part of our new Programmatic collection.
                  Networks configured for our programmatic views will appear among the omnisearch results.
                </p>

                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <div
                      v-if="isInternalUser"
                      class="cursor-pointer"
                    >
                      <p
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        You can also view which organizations are configured for this report by visiting
                        the Organizations page.
                        <span
                          class="toasted--text font-weight-bold"
                        >
                          *
                        </span>
                      </p>
                    </div>
                  </template>

                  <span>Internal Note</span>
                </v-tooltip>

                <p>
                  Available to a limited internal audience.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request access.
                </p>
              </div>

              <div>
                <h4>
                  Network Health Reports
                </h4>

                <p>
                  Monitor the network to spot unusual behavior across any device or platform.
                </p>

                <p>
                  The Network Health Report is part of our new Programmatic collection.
                  Networks configured for our programmatic views will appear among the omnisearch results.
                </p>

                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <div
                      v-if="isInternalUser"
                      class="cursor-pointer"
                    >
                      <p
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        You can also view which organizations are configured for this report by visiting
                        the Organizations page.
                        <span
                          class="toasted--text font-weight-bold"
                        >
                          *
                        </span>
                      </p>
                    </div>
                  </template>

                  <span>Internal Note</span>
                </v-tooltip>

                <p>
                  Available to a limited internal audience.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request access.
                </p>
              </div>

              <div>
                <h4>Numerous Stability Enhancements</h4>

                <p>
                  Among recent updates, we've extended our filters' automated tests,
                  iterated on our sign-in error messages to provide users with more context,
                  and updated our error handling to notify users when their
                  network is unavailable.
                </p>
              </div>
            </div>

            <div
              id="2021-04"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                April 2021
              </h3>

              <div>
                <h4>
                  Export as XLSX
                </h4>

                <p>
                  To access this feature, click on a report's Export icon, located in every report's header.
                </p>
              </div>

              <div>
                <h4>
                  AdX & OB Reports
                </h4>

                <p>
                  Monitor AdX & OB delivery to ensure all partners are delivering.
                </p>

                <p>
                  The AdX & OB Reports, fka the Yield Partner Reports, are part of our new Programmatic collection.
                  Networks configured for our programmatic views will appear among the omnisearch results.
                </p>

                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <div
                      v-if="isInternalUser"
                      class="cursor-pointer"
                    >
                      <p
                        v-bind="tooltipAttrs"
                        v-on="tooltipOn"
                      >
                        You can also view which organizations are configured for this report by visiting
                        the Organizations page.
                        <span
                          class="toasted--text font-weight-bold"
                        >
                          *
                        </span>
                      </p>
                    </div>
                  </template>

                  <span>Internal Note</span>
                </v-tooltip>

                <p>
                  Available to a limited internal audience.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request access.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                      <div class="cursor-pointer">
                        <span
                          v-bind="tooltipAttrs"
                          v-on="tooltipOn"
                        >
                          New Columns and Filters in Organization
                        </span>
                        <span
                          class="toasted--text"
                        >
                          *
                        </span>
                      </div>
                    </template>

                    <span>Internal Note</span>
                  </v-tooltip>
                </h4>

                <p>
                  With the addition of new programmatic reports, we've exposed columns to
                  the Organization views indicating whether an organization is configured
                  for programmatic reports.
                </p>
              </div>

              <div>
                <h4>
                  Merge Rows to Summarize
                </h4>

                <p>
                  Users can merge rows to create summaries, e.g. Advertiser Summaries.
                  Combine with grouping to gain insights into performance across categories.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>Published Backlog</h4>

                <p>
                  Grants our users visibility over their feedback's movement through our process.
                  This first iteration captures much of the user feedback provided during Q1's user scoring
                  exercises.
                </p>
              </div>

              <div>
                <h4>Numerous Stability Enhancements</h4>

                <p>
                  Highlights include various refactors to optimize performance at load time,
                  plus vast improvements to our automated tests' reporting, particularly around coverage.
                </p>
              </div>
            </div>

            <div
              id="2021-03"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                March 2021
              </h3>

              <div v-if="isInternalUser">
                <h4>
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                      <div class="cursor-pointer">
                        <span
                          v-bind="tooltipAttrs"
                          v-on="tooltipOn"
                        >
                          Extended User Analytics
                        </span>
                        <span
                          class="toasted--text"
                        >
                          *
                        </span>
                      </div>
                    </template>

                    <span>Internal Note</span>
                  </v-tooltip>
                </h4>

                <p>
                  Our Google Analytics code has been updated for accuracy
                  and consistency, making our reports more reliable and
                  insightful. Event captures are next.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                      <div class="cursor-pointer">
                        <span
                          v-bind="tooltipAttrs"
                          v-on="tooltipOn"
                        >
                          Organization and Person Profiles: New Look & Linked Network Codes
                        </span>
                        <span
                          class="toasted--text"
                        >
                          *
                        </span>
                      </div>
                    </template>

                    <span>Internal Note</span>
                  </v-tooltip>
                </h4>

                <p>
                  Organization and Person pages have been restructured to
                  accommodate the growing amount of information we're
                  capturing through our Internal Data efforts. As a bonus,
                  network codes now link to GAM.
                </p>
              </div>

              <div>
                <h4>
                  Adds Advertiser Column and Filter to Discrepancy
                </h4>

                <p>
                  Group, filter, and sort your Discrepancy reports by
                  Advertiser Name using this new column and filter.
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to have specific advertisers prefiltered from your reports.
                </p>
              </div>

              <div>
                <h4>Additional Columns & Corresponding Filters Now on Pacing</h4>

                <p>
                  Analyze your revenue at risk using the new revenue-related
                  columns, listed below. Users can combine revenue filters
                  with the new Custom Fields to create custom reports.
                </p>

                <p>
                  <ul>
                    <li>All Revenue</li>
                    <li>Potential Lost Revenue</li>
                    <li>Potential Lost Revenue Percentage</li>
                    <li>Custom Fields</li>
                  </ul>
                </p>

                <p>
                  Details, including calculations, have been defined in the
                  Pacing legend, available in Pacing reports' headers.
                </p>
              </div>

              <div>
                <h4>
                  Adds CTR Column to Line Item-Level Reports
                </h4>

                <p>
                  Historical and Creatives report also received new columns.
                  Users will find Click-Thru Rate (CTR) has been exposed
                  beside the existing Clicks column.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                      <div class="cursor-pointer">
                        <span
                          v-bind="tooltipAttrs"
                          v-on="tooltipOn"
                        >
                          Demo Mode
                        </span>
                        <span
                          class="toasted--text"
                        >
                          *
                        </span>
                      </div>
                    </template>

                    <span>Internal Note</span>
                  </v-tooltip>
                </h4>

                <p>
                  Share your iAdOps view without sharing sensitive data.
                  Click on the user profile in the sidebar to access
                  Demo Mode via the Settings menu.
                </p>
              </div>

              <div>
                <h4>Limited Programmatic Views</h4>

                <p>
                  Ad Exchange data is available to a limited audience.
                  More to come as we continue developing these views
                  with early adopters' guidance.
                </p>

                <p>
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    Open a ticket
                  </a>
                  to request early access to these views.
                </p>
              </div>

              <div>
                <h4>Numerous Stability Enhancements</h4>

                <p>
                  Minor enhancements were made to improve the general
                  user experience. For example, layouts were altered
                  to reduce breaks across browsers and devices.
                </p>
              </div>
            </div>

            <div
              id="2021-02"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                February 2021
              </h3>

              <p>
                Originally released to
                <a
                  href="https://beta.iadops.com"
                  target="_blank"
                >
                  Beta
                </a>
                in February,
                these updates are on iadops.com as of March 2021.
              </p>

              <div>
                <h4>
                  Data Freshness Indicator Represents When Data Is Refreshing
                </h4>

                <p>
                  The latest updates to the Data Status Indicator inform
                  users when the data refresh cycle is ongoing. During this time,
                  databases are updating and page loads may be slow.
                </p>
              </div>

              <div>
                <h4>
                  Sales Person, Trafficker, and Secondary Trafficker Columns
                </h4>

                <p>
                  Three additional columns are available in Pacing today:
                  Sales Person, Trafficker, and Secondary Traffickers.
                  Use the column picker in the header to manage their visibility
                  in the browser and export.
                </p>
              </div>

              <div>
                <h4>Numerous Stability Enhancements</h4>

                <p>
                  We're working behind the scenes to assure the app remains
                  stable, responsive, and secure.
                </p>
              </div>
            </div>

            <div
              id="2021-01"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                January 2021
              </h3>

              <div>
                <h4>Dynamic Grouping</h4>

                <p>
                  Now available to all users, dynamic grouping empowers
                  users to group table rows by an array of properties
                  based on the report. Pacing and Discrepancy reports
                  can be grouped by Advertiser, Order, or
                  Advertiser &amp; Order.
                </p>

                <p>
                  This option is located in the Filter menu in the top right
                  of the application's header.
                </p>

                <p>
                  Later iterations will empower users to save group preferences,
                  group by a larger set of properties, and gain group-level
                  insights via calculated metrics.
                </p>
              </div>

              <div>
                <h4>
                  Layout Options
                </h4>

                <p>
                  A new Layout option has been added to the Action icons
                  in the header. Clicking on the icon to the left of the
                  Filters menu reveals a dropdown. Options include "Toggle
                  Groups" (see Dynamic Grouping) and "Toggle Saved Filters."
                </p>

                <p>
                  When a Group property is set, users can use the "Toggle
                  Groups" option to toggle table rows in and out of view.
                  Performance of this feature will scale relative to the
                  number of tables on the page.
                </p>

                <p>
                  "Toggle Groups" is disabled if no Group property has
                  been set. Help text has been provided in context to guide
                  new users.
                </p>

                <p>
                  "Toggle Saved Filters" hides the Saved Filters above the
                  line item content, lending users greater screen real
                  estate for tabular analysis when necessary.
                </p>
              </div>

              <div>
                <h4>
                  OSI Column Relocated
                </h4>

                <p>
                  The OSI column has been moved to the right of Priority
                  for easier viewing when all columns are visible.
                </p>
              </div>

              <div>
                <h4>
                  Legend Icon
                </h4>

                <p>
                  We added this new action icon in the header area to
                  make it easy to view the OSI, color definitions, and
                  discrepancy calculations. The legend will provide
                  in-context info depending on whether you're in the
                  Pacing or Discrepancy report views.
                </p>
              </div>

              <div>
                <h4>
                  Action Icon Tooltips
                </h4>

                <p>
                  These floating tooltips will appear on hover to help
                  easily identify each icon.
                </p>
              </div>
            </div>

            <div
              id="2020-12"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                December 2020
              </h3>

              <div>
                <h4>Dynamic Grouping (BETA)</h4>

                <p>
                  Group table rows by an array of properties based on
                  the report. Pacing and Discrepancy reports can be
                  grouped by advertisers, orders, or a combination of
                  the two.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>Internal Enhancements</h4>

                <p>
                  Addresses row height issues impacting Org table<br>
                  Assert User Record Management Rules via Forms<br>
                  Iterates on User Settings and Related Forms<br>
                  Filter and sort Person’s generated properties (level, role, manager, org)<br>
                  Org Pages Expose New Properties and Filters, Including Network Code, Pacing, Discrepancy, and Timesheet Information<br>
                  Org Profile Pages Sport a New Look to Accommodate Team-Level Data<br>
                  Person profile pages include Access and Organization properties<br>
                  Exposes Change Log details to Person and Org profile pages<br>
                </p>
              </div>

              <div>
                <h4>Other Enhancements</h4>

                <p>
                  Moves Actions to Left of Line Items<br>
                  Updated styles for consistent branding and layouts<br>
                </p>
              </div>
            </div>

            <div
              id="2020-11"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                November 2020
              </h3>
              <div>
                <h4>Clicks &amp; CTR</h4>

                <p>
                  Users expressed Clicks and Click-Through Rate are
                  important metrics for them. As such, we’ve added
                  them as columns to all Pacing reports. View, sort,
                  and filter the Pacing report by Clicks and CTR.
                </p>
              </div>

              <div>
                <h4>Column-Picking</h4>

                <p>
                  To hone in on specific values or otherwise limit the data
                  on the page, use the new Column-Picker to the left of a
                  table's column headers.
                </p>

                <p>
                  Users are able to choose the columns visible in their
                  Pacing and Discrepancy Reports. While the first
                  iteration will focus on the single-session experience,
                  later iterations will save column preferences across
                  users’ sessions and devices.
                </p>
              </div>

              <div>
                <h4>Export Creatives Report</h4>

                <p>
                  Take Creatives data with you. Share it, transform it,
                  or simply save it for later reference. Users will find
                  a new export icon appearing in the top left of all
                  Historical and Creative Reports.
                </p>
              </div>

              <div>
                <h4>Export Historical Report</h4>

                <p>
                  Take Historical Pacing data with you. Share it, transform
                  it, or simply save it for later reference. Users will
                  find a new export icon appearing in the top left of all
                  Historical and Creative Reports.
                </p>
              </div>

              <div>
                <h4>OmniSearch Enhancements: See All Search Results</h4>

                <p>
                  Users can toggle between viewing all search results or
                  limiting them to 3 per section. View all results for a
                  search by pressing the results total, then click again
                  to limit them. Result totals appear to the left of each
                  results’ section headers.
                </p>
              </div>

              <div>
                <h4>
                  OmniSearch Enhancements: Type-to-Search From Any Character Index
                </h4>

                <p>
                  Users can search for teams quickly with these updates to
                  the omnisearch's configuration. For example, typing "AMP"
                  would produce "Example." Searches will still prioritize
                  matches at the start of the text.
                </p>
              </div>

              <div>
                <h4>Smarter User Analytics</h4>

                <p>
                  Adds Event Tracking<br>
                </p>
              </div>
            </div>

            <div
              id="2020-10"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                September - October 2020
              </h3>

              <div>
                <h4>Creative Delivery Report</h4>

                <p>
                  In addition to displaying the creative ad size
                  delivered per each line item in the UI, we have
                  also released an option for downloading the 10-day
                  Creative Report for each line item. This report is
                  available in the hamburger menu associated with
                  each line item. It is one of the options alongside
                  Tearsheets and the Historical report.
                </p>
              </div>

              <div>
                <h4>Creative Ad Sizes</h4>

                <p>
                  We have included this column based on 'Ad Size Delivered'
                  in Google's metric. This metric is not quite the same as
                  which ad sizes have delivered for a specific line, but it
                  is in line with Google's own reporting. The column has
                  been added to the UI report alongside a filter option,
                  where users can include and exclude one or many ad sizes.
                </p>
              </div>

              <div>
                <h4>Top-Level Ad Units</h4>

                <p>
                  Users can include and exclude one or many top level
                  ad units by name.
                </p>
              </div>

              <div>
                <h4>Saved Filters</h4>

                <p>
                  Users can
                  <a
                    href="mailto:tech@oao.freshdesk.com"
                    target="_blank"
                  >
                    open a ticket
                  </a>
                  to request user-specific filters at page load.
                </p>
              </div>

              <div v-if="isInternalUser">
                <h4>External User Migration</h4>
                <p>
                  Adds external users to our user base<br>
                  Eases the first-time external user's navigation experience
                  by loading their default at the Pacing route without any
                  user input.<br>
                  Employs GAM team logic to offer team-based reports.<br>
                  Updates the omni-search to allow team-based searches.<br>
                  Updates the authorization logic to allow multi-team configurations.<br>
                  Updates error messaging at SignIn &amp; SignOut to guide
                  users through authentication and authorization, as well as
                  error states they may enter in the process.
                </p>
              </div>

              <div>
                <h4>Stability &amp; Experience Enhancements</h4>

                <p>
                  Reenforces filter logic around operators to assure against bugs and regressions.<br>
                  For consistency, replaces "Local" label with "1st Party" in Discrepancy Creatives Report<br>
                  Extends User Docs<br>
                  Improves multi-device testing<br>
                  Iterates toward stricter security<br>
                  Fixes styling and layout errors impacting modals<br>
                  Fixes sorting indicator issues<br>
                  Refactors internal utilities<br>
                </p>
              </div>

              <div>
                <h4>Browser-Level Enhancements</h4>
                <p>
                  To assure users have the latest and greatest each time
                  they engage with the application, we’ve optimized the
                  browser experience. Users are now prompted to act when
                  sessions expire or the server updates with new data.
                  This is especially useful for users who keep iAdOps
                  open all day and were encountering errors on form
                  submissions due to extended idle times.
                </p>

                <p>
                  Prompts Sign In When Idle Time Exceeds 30 Minutes<br>
                  Prompts Sign In When Auth State Changes<br>
                  Prompts Refresh When New Content from Server<br>
                  <br>
                  Supports Chrome Mobile for iOS and Android<br>
                  Improves Cacheing Experience with Versioning<br>
                </p>
              </div>

              <div>
                <h4>Assures Discrepancy Table Headers Remain Visible on Scroll</h4>
                <p>
                  Users need to maintain column context as they scroll
                  through data-dense tables. To do this, we’ve pinned
                  the Discrepancy table’s headers to the top of the page.
                  The user can scroll through numerous rows without losing
                  sight of column names.
                </p>
              </div>

              <div>
                <h4>Improves Loading Experience at Time and Overview Pages</h4>
                <p>
                  The views were rendering before the data was ready.
                  We’ve since attached a loader experience to these
                  pages to assure content doesn’t render until it’s ready.
                </p>
              </div>

              <div>
                <h4>Enforces Filter Rules: Assert Exclusion over Inclusions</h4>
                <p>
                  We received a bug report revealing the combination of
                  OSI values NEQ 0 and LTE 96 did not exclude 0 OSIs
                  from the result set. This has been resolved and granted
                  thorough testing to assert expectations.
                </p>
              </div>

              <div>
                <h4>Replaces Time Type Operator INCL/EXCL with EQ/NEQ</h4>
                <p>
                  The Time-related filter forms include a Time Type filed
                  that was using INCL/EXCL, as other text inputs are.
                  However, it required a “strict” set of operators, EQ/NEQ,
                  in place of INCL/EXCL.
                </p>

                <p>
                  This addresses the reported cases of, for example,
                  NON-BILLABLE appearing in response to INCL BILLABLE.
                  Though it’s not a bug, it’s not what the user expects;
                  they expect EQ/NEQ.
                </p>
              </div>

              <div>
                <h4>Adds Feedback Form to Header Menu</h4>
                <p>
                  Users are encouraged to leave feedback from anywhere
                  in the application. Clicking the icon in the upper
                  right-most of the screen opens a feedback form in
                  a new tab.
                </p>

                <v-img
                  class="justify-self-center elevation-3 mb-6"
                  src="../../assets/images/release_202008_feedback-form.png"
                  alt=""
                  height="auto"
                  max-width="300px"
                />
              </div>
            </div>

            <div
              id="2020-08"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                August 2020
              </h3>

              <div>
                <h4>Header Icons Displays Browser Tooltip On Hover</h4>
                <p>
                  Hovering over the header menu’s icons will produce a
                  browser tooltip with the icon’s title. This addresses
                  accessibility concerns. Future iterations will replace
                  the native tooltip with a customized tooltip branded
                  for our application.
                </p>
              </div>

              <div>
                <h4>Filters Reset When Navigating Between Clients</h4>
                <p>
                  Any filters set, including Saved Filters, will be unset
                  when the user navigates to another client, typically
                  done via OmniSearch.
                </p>
              </div>

              <div>
                <h4>User Permissions</h4>
                <p>
                  We’ve implemented User Permissions to offer user-specific
                  experiences. This lays the groundwork for Settings our
                  users can use to customize their experience and reports.
                </p>
              </div>
            </div>

            <div
              id="2020-07"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                July 2020
              </h3>

              <div>
                <h4>Beta Releases</h4>

                <p>
                  Data Freshness Timestamp on Pacing and Discrepancy<br>
                  Time-Based OSI<br>
                  Discrepancy Percentage Calculation Refinements<br>
                  Discrepancy Percentage Filter Updates, incl. New Range Options<br>
                  Exclude/Include Options on Text Fields<br>
                  Numerical Operators EQ, NEQ, GTE, and LTE<br>
                  Boolean Operators IS and NOT<br>
                  Editable Numerical Values<br>
                  Impressions Yesterday Field<br>
                  Saved Filters Update the Filter Form<br>
                  Search Instructions and Prominent Input on the Welcome Page<br>
                  Saved Filters’ Info Dialogs for In-Context Information<br>
                  Saved Filters’ Rules Refined. See Definitions Defined In-Context<br>
                  Missing Creatives Column<br>
                  Missing Creatives Filter to Pacing Filter Form<br>
                  User Docs Link Appears in Search Dropdown<br>
                  User Docs Gains Prominent Position and New Icon in Drawer<br>
                  User Docs Expanded for External User Onboarding<br>
                </p>
              </div>
            </div>

            <div
              id="2020-06"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                June 2020
              </h3>

              <div>
                <h4>Beta Releases</h4>

                <p>
                  GAM Network Teams, Available via OmniSearch<br>
                  Added Pacing At Risk as a Saved Filter<br>
                  Saved Filters Toggle on Click<br>
                  Options Update Live to Represent the Report’s Full Data Set<br>
                  Filters Indicator Informs Users When Filters are Set<br>
                  Exclusion Filter Options<br>
                  OSI Calculation Refinements<br>
                  Discrepancy Percentage Error Handling<br>
                  Discrepancy Percentage Filter<br>
                  Exposed the Status Column<br>
                  Added Status Filter to the Delivery Filter Form<br>
                  Draggable Dialogs for Pacing and Discrepancy Sub-Reports<br>
                  Order Count on Click of Saved Filters<br>
                  Time Summaries Update Live on Add, Edit, and Delete of an Entry<br>
                  Assert a 250 Character Limit on Notes<br>
                  Added Weekly Time Totals<br>
                  Added Daily Time Totals<br>
                  Added Monthly Time Total (No Breakdown)<br>
                  Added Overview Summaries<br>
                  Corrected Color Swatch Applied to OSI & Discrepancy Percentage<br>
                  AlphaNumerical Sorting of Dropdown Options<br>
                  Max-Width Set on Dropdown Menus<br>
                  Consistent Handling of Time Entries Across All Timezones<br>
                  Month Label on Time Summaries<br>
                </p>
              </div>

              <div>
                <h4>Line Item Exclusions</h4>

                <p>
                  - status is draft<br>
                  - status is archived<br>
                  - end date > 10 days ago + delivery has not been<br>
                  - start date > 14 days from now<br>
                  - pre-bid orders/line items as indicated by order label<br>
                </p>
              </div>
            </div>

            <div
              id="2020-05"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                May 2020
              </h3>

              <div>
                <h4>Beta Releases</h4>

                <p>
                  Creative-Level Discrepancy with Creative Deep Links<br>
                  Expanded Discrepancy Legend with Calculations<br>
                  OmniSearch<br>
                  Line Item Exclusions (details above)<br>
                  Hours Duration<br>
                  Internal Data Filters<br>
                  Organization Profile Page with MetaData Exposed<br>
                  Person Profile Page with MetaData Exposed<br>
                  Project Profile Page with MetaData Exposed<br>
                  MultiSelect Smart Search<br>
                  Date Input UX Improvements<br>
                  UX &amp; Performance Improvements at Page Load<br>
                  UX &amp; Performance Improvements at Saved Reports<br>
                  Minor Dependency Updates<br>
                  QA &amp; Automated Testing Updates<br>
                  2020 Roadmap Updates<br>
                </p>
              </div>
            </div>

            <div
              id="2020-04"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                March - April 2020
              </h3>

              <div>
                <h4>Beta Releases</h4>

                <p>
                  Export Data from Any Report View<br>
                  Extended API Endpoints<br>
                  Stability &amp; Performance Improvements<br>
                  Complete Re-Architecting of UI for Non-Delivery Data<br>
                  UX Improvements at Loading<br>
                  UX Improvements at Forms<br>
                  UX Improvements at Auth Error States<br>
                  Minor Dependency Updates<br>
                  QA &amp; Automated Testing Updates<br>
                  2020 Roadmap Updates<br>
                </p>
              </div>
            </div>

            <div
              id="2020-02"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                February 2020
              </h3>

              <div>
                <h4>Beta Releases</h4>

                <p>
                  Preview Creatives<br>
                  Get Tearsheets<br>
                  Priority Mult-Select<br>
                  Slimmer Drawer with Flat Navigation<br>
                  Press Enter to Submit Filter<br>
                  Improved Filter Experience<br>
                  Improved Table Responsiveness<br>
                  Updated OSI Logic<br>
                  Improved Performance<br>
                  Minor Dependency Updates<br>
                  Database Migrations<br>
                  GAM API Facade<br>
                  Tearsheets API<br>
                  Test Coverage Reporting<br>
                  2020 Roadmap Updates<br>
                  Fixes Horizontal Scrolling<br>
                  Fixes Vertical Scrolling<br>
                </p>
              </div>
            </div>

            <div
              id="2020-01"
            >
              <h3
                class="mb-3 subtitle-1 primary--text font-weight-bold"
              >
                January 2020
              </h3>

              <div>
                <h4>Beta Releases</h4>

                <p>
                  Discrepancy Report<br>
                  AdJuster ETL Backend Optimizations<br>
                  ETL Process Improvements Targetting Overhead<br>
                  User Activity Tracking thru Google Analytics<br>
                  Master Data Organization and Person Data Iterations<br>
                  Viewability Percentage Rounding to 10th Place<br>
                  Viewability Percentage API Output Fix<br>
                  Browser Cache Busting<br>
                  Browser Performance Improvements<br>
                  Progressive Web App Capabilities, incl. Install<br>
                  Offline Access<br>
                  Improved Mobile Responsiveness<br>
                  New Layout &amp; Full-Screen Table View for Better Data Analysis<br>
                  Improved Filter Experience<br>
                  Major Dependency Updates<br>
                  State Management Refactors<br>
                  Test Coverage Reporting<br>
                  2020 Roadmap Updates<br>
                  Homepage Error Message on Auth Fail<br>
                  Captures OSI Edge Cases<br>
                  Captures Booked Edge Cases<br>
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'ReleaseNotes',
  computed: {
    ...mapGetters(['isDevUser', 'isInternalUser']),
  },
}
</script>
